import {
  selectIsFreeUser,
  selectIsPremiumUser,
  selectNextPlanName,
  selectSharingInstagramPlatform,
  selectUserInfo,
} from '@/redux/user/selectors';
import {
  TrialPlanType,
  TrialType,
  UpgradeIntroType,
} from '@/components/upgrade-popup/types';
import type { INewsArticle } from '@/types/news';
import type { Pages, PageSections } from '@/types/pages';
import type { UserEditInfoPopupObject } from '@/types/user';
import type { IVideo } from '@/types/video';
import { resetConnectSocial } from '../connect-social/actions';
import type { AppThunkDispatch, AppThunkResult } from '../types';
import * as actionTypes from './actionTypes';
import type * as types from './types';
import { shouldAskForProfileCompletion } from '@/src/containers/CompleteYourProfilePopup/utils/displayPopupLogic';
import { getBrokerageName } from '@/src/containers/TrialPopups/AvantiUpgradeToTrialPopup/utils/getBrokerageName';
import {
  isEligibleForBrokerageTrial,
  isUserEligibleForTrial,
} from '@/src/core/utils/trials';
import type { ISelectedContent } from '@/types/content-factory';
import { Socials as SocialsTypes } from '@/src/shared/types/socials';
import { shouldSeeVideoPopupV2 } from '@/src/containers/VideoPopup/V2/utils/abTesting';
import { ITestimonial } from '@/types/testimonial';
import { UpgradePlans } from '@/types/subscription';

export const openShareConfirmPopup =
  (
    platform: string,
    isDefaultVideo: boolean,
    page: Pages,
    onSuccess?: () => void,
    onFailed?: (error: string) => void
  ): AppThunkResult =>
  async (dispatch: AppThunkDispatch, getState) => {
    const state = getState();
    const user = selectUserInfo(state);
    const isFreeUser = selectIsFreeUser(state);
    const instagramType = selectSharingInstagramPlatform(state);

    // TODO: Use a better thing instead of brokerage id
    // Handle upgrade to trial popup
    if (user.brokerage_id == 26 && isFreeUser) {
      const nextPlan = selectNextPlanName(state);
      dispatch(
        openUpgradePopup({
          isProOffer: false,
          selectedPlan: nextPlan.key,
          source: page,
          introType: UpgradeIntroType.General,
        })
      );

      return;
    }

    let platformToUse = platform;
    if (
      platform === SocialsTypes.Instagram &&
      instagramType === SocialsTypes.InstagramBusiness
    ) {
      platformToUse = SocialsTypes.InstagramBusiness;
    }

    dispatch(resetConnectSocial());
    dispatch(
      requestShareConfirmPopup(
        platformToUse,
        isDefaultVideo,
        page,
        onSuccess,
        onFailed
      )
    );
  };

const requestShareConfirmPopup = (
  platform: string,
  isDefaultVideo: boolean,
  page: Pages,
  onSuccess?: () => void,
  onFailed?: (error: string) => void
): types.OpenShareConfirmAction => ({
  type: actionTypes.OPEN_SHARE_CONFIRM,
  payload: {
    name: 'share-confirm-popup',
    shareConfirm: { platform, isDefaultVideo, page, onSuccess, onFailed },
  },
});

export const openTrialPopup = (
  source: PageSections,
  trialType: TrialType = TrialType.TwoWeeks,
  plan?: TrialPlanType
) => {
  return {
    type: actionTypes.OPEN_UPGRADE_POPUP,
    payload: {
      name: 'upgrade-to-trial-popup-for-normal-users',
      upgrade: {
        source,
        introType: UpgradeIntroType.General,
        trial: {
          type: trialType,
          plan,
        },
      },
    },
  };
};

export const closeCurrentPopup = () => ({
  type: actionTypes.CLOSE_CURRENT_POPUP,
});

export const closePopup = (popupId: types.Popups): types.ClosePopupAction => ({
  type: actionTypes.CLOSE_POPUP,
  payload: {
    popupId,
  },
});

export const closeAllPopup = (): types.CloseAllPopupAction => ({
  type: actionTypes.CLOSE_ALL_POPUP,
});

export const openNewPopup = (name: types.Popups): types.OpenNewPopup => ({
  type: actionTypes.OPEN_NEW_POPUP,
  payload: {
    name,
  },
});

export const openDemoPopup = (
  payload?: types.OpenDemoPopup['payload']['demo']
): types.OpenDemoPopup => ({
  type: actionTypes.OPEN_DEMO_POPUP,
  payload: {
    name: 'book-demo-popup',
    demo: payload,
  },
});

export const openFreePlanPopup = (source: string): types.OpenFreePlanPopup => ({
  type: actionTypes.OPEN_FREE_PLAN_POPUP,
  payload: {
    name: 'free-plan-popup',
    source,
  },
});

export const openConnectSocialsPopup = (
  params: types.OpenConnectSocialsPopup['payload']['connectSocial']
): types.OpenConnectSocialsPopup => ({
  type: actionTypes.OPEN_CONNECT_SOCIALS_POPUP,
  payload: {
    name: 'connect-social-popup',
    connectSocial: params,
  },
});

export const openAllSocialsPlanPopup = (
  onSuccess?: () => void
): types.OpenAllSocialsPopup => ({
  type: actionTypes.OPEN_ALL_SOCIALS_POPUP,
  payload: {
    name: 'all-social-connect-popup',
    connectAllSocial: { onSuccess },
  },
});

export const openEditVideoBrandingPopup = (
  params?: types.OpenEditVideoBrandingPopup['payload']['editVideoBranding']
): types.OpenEditVideoBrandingPopup => ({
  type: actionTypes.OPEN_EDIT_VIDEO_BRANDING_POPUP,
  payload: {
    name: 'edit-video-branding-popup',
    editVideoBranding: {
      ...params,
      reloadWindowOnSave: params?.reloadWindowOnSave ?? true,
    },
  },
});

export const openRegisterPopup = (
  popupTitle: string,
  ctaText: string,
  source?: string,
  query?: string,
  user_type_id?: number,
  redirectUrl?: string
): types.RegisterPopup => ({
  type: actionTypes.OPEN_REGISTER_POPUP,
  payload: {
    name: 'register-popup',
    popupTitle,
    ctaText,
    source,
    query,
    redirectUrl,
    ...(user_type_id && { user_type_id }),
  },
});

export const openSimpleVideoPlayerPopup = (
  popupTitle: string,
  videoURL: string
): types.SimpleVideoPlayerPopup => ({
  type: actionTypes.OPEN_SIMPLE_VIDEO_PLAYER_POPUP,
  payload: {
    name: 'simple-video-player-popup',
    popupTitle,
    videoURL,
  },
});

export const openVideoPopupV1 = (
  data: types.OpenVideoPopup['payload']['video']
) => {
  return {
    type: actionTypes.OPEN_VIDEO_POPUP,
    payload: {
      name: 'single-video-popup',
      video: { ...data },
    },
  };
};

export const openVideoPopupV2 = (
  data: types.OpenVideoPopupV2['payload']['video']
) => {
  return {
    type: actionTypes.OPEN_VIDEO_POPUP_V2,
    payload: {
      name: 'video-popup-v2',
      video: { ...data },
    },
  };
};

export const openVideoPopup =
  ({
    dontAskForProfileCompletion,
    ...data
  }: types.OpenVideoPopup['payload']['video'] & {
    dontAskForProfileCompletion?: boolean;
  }): AppThunkResult =>
  (dispatch: AppThunkDispatch, getState) => {
    const state = getState();
    const user = selectUserInfo(state);
    if (!dontAskForProfileCompletion && shouldAskForProfileCompletion(user)) {
      dispatch(openCompleteYourProfilePopup({ intent: 'see-video' }));
      return;
    }
    if (shouldSeeVideoPopupV2(user)) {
      dispatch(openVideoPopupV2(data));
    } else {
      dispatch(openVideoPopupV1(data));
    }
  };

export const openPublicVideoPopup = (
  video: { video: IVideo; videoList?: IVideo[]; videoIndex?: number },
  page: Pages
): types.OpenPublicVideoPopup => ({
  type: actionTypes.OPEN_PUBLIC_VIDEO_POPUP,
  payload: {
    name: 'single-public-video-popup',
    video,
    page,
  },
});

export const openPublicVideoSimpleFormPopup = (
  video: { video: IVideo; videoList?: IVideo[]; videoIndex?: number },
  page: Pages,
  regionName: string
): types.OpenPublicVideoSimpleFormPopup => ({
  type: actionTypes.OPEN_PUBLIC_VIDEO_SIMPLE_FORM_POPUP,
  payload: {
    name: 'single-public-video-simple-form-popup',
    video,
    page,
    regionName,
  },
});

export interface OpenUpgradePopupProps {
  isYearly?: boolean;
  selectedPlan: UpgradePlans;
  introType: UpgradeIntroType;
  source: string;
  isProOffer?: boolean;
  adsTotal?: number;
  adsData?: any;
  showPayment?: boolean;
  onAdsPayment?: () => void;
  onCancel?: () => void;
  autoClosePopupOnCancel?: boolean;
  onSuccess?: () => void;
  openAsIntroType?: boolean;
  ignoreTrial?: boolean;
  reason?: string;
  trigger?: string;
  couponCode?: string;
  applyCoupon?: boolean;
  subscriptionFor?: string;
}

export const openUpgradePopup =
  (data: OpenUpgradePopupProps): AppThunkResult =>
  (dispatch: AppThunkDispatch, getState) => {
    const state = getState();
    const user = selectUserInfo(state);
    const brokerageName = getBrokerageName(user.brokerage_id);
    // TODO: We should use a dynamic thing instead of brokerage id

    if (isEligibleForBrokerageTrial(user) && !data.ignoreTrial) {
      dispatch({
        type: actionTypes.OPEN_UPGRADE_POPUP,
        payload: {
          name: 'upgrade-to-trial-popup',
          upgrade: {
            source: data.source,
            selectedPlan: data.selectedPlan,
            isProOffer: data.isProOffer,
            introType: data.introType,
            adsTotal: data.adsTotal,
            adsData: data.adsData,
            onAdsPayment: data.onAdsPayment,
            isYearly: data.isYearly,
            showPayment: data.showPayment,
            onCancel: data.onCancel,
            onSuccess: data.onSuccess,
            reason: data.reason,
            trigger: data.trigger,
            brokerageName,
            autoClosePopupOnCancel: data.autoClosePopupOnCancel,
          },
        },
      });
    } else if (
      isUserEligibleForTrial(user, data.selectedPlan) &&
      !data.ignoreTrial
    ) {
      dispatch(openTrialPopup('agent_dashboard_index'));
    } else {
      dispatch({
        type: actionTypes.OPEN_UPGRADE_POPUP,
        payload: {
          name: 'upgrade-popup',
          upgrade: {
            source: data.source,
            selectedPlan: data.selectedPlan,
            isProOffer: data.isProOffer,
            introType: data.introType,
            adsTotal: data.adsTotal,
            adsData: data.adsData,
            onAdsPayment: data.onAdsPayment,
            isYearly: data.isYearly,
            showPayment: data.showPayment,
            onCancel: data.onCancel,
            autoClosePopupOnCancel: data.autoClosePopupOnCancel,
            onSuccess: data.onSuccess,
            reason: data.reason,
            trigger: data.trigger,
            couponCode: data.couponCode,
            applyCoupon: data.applyCoupon,
            subscriptionFor: data.subscriptionFor,
          },
        },
      });
    }
  };

export const openUpgradeSuccessPopup = (
  upgradeSuccessPopupData?: types.OpenUpgradeSuccessPopup['payload']['upgradeSuccessPopupData']
): types.OpenUpgradeSuccessPopup => ({
  type: actionTypes.OPEN_UPGRADE_SUCCESS_POPUP,
  payload: {
    name: 'upgrade-success-popup',
    upgradeSuccessPopupData,
  },
});

export const openManageUserGroupPopup = (
  manageUserGroupPopupData?: types.OpenManageUserGroupPopupAction['payload']['manageUserGroupPopupData']
): types.OpenManageUserGroupPopupAction => ({
  type: actionTypes.OPEN_MANAGE_USER_GROUP_POPUP,
  payload: {
    name: 'manage-user-group-popup',
    manageUserGroupPopupData,
  },
});

export const openSchedulePopupAction = (
  item: types.ScheduleItem,
  payload?: string,
  source?: string,
  onSuccess?: () => void,
  options?: types.SchedulePopupOptions
): types.OpenSchedulePopupAction => ({
  type: actionTypes.OPEN_SCHEDULE_POPUP,
  payload: {
    name: 'schedule-popup',
    schedule: { item, payload, source, onSuccess, options },
  },
});

export const openCalendarSchedulePopupAction = (
  item: types.ScheduleItemCalendar,
  payload?: string,
  source?: string,
  onSuccess?: () => void
): types.OpenCalendarSchedulePopupAction => ({
  type: actionTypes.OPEN_CALENDAR_SCHEDULE_POPUP,
  payload: {
    name: 'schedule-popup-calendar',
    scheduleCalendar: { item, payload, source, onSuccess },
  },
});

export const openOnboardingSharePopup = (): types.OpenOnboardingSharePopup => ({
  type: actionTypes.OPEN_ONBOARDING_SHARE_POPUP,
  payload: {
    name: 'onboarding-share-popup',
    share: {},
  },
});

export const openFollowUpTemplatesPopup =
  (): types.OpenFollowUpTemplatesPopup => ({
    type: actionTypes.OPEN_FOLLOW_UP_TEMPLATES_POPUP,
    payload: {
      name: 'follow-up-templates-popup',
      share: {},
    },
  });

export const openFollowUpMessagesPopup = (
  selectedLead: any
): types.OpenFollowUpMessagesPopup => ({
  type: actionTypes.OPEN_FOLLOW_UP_MESSAGES_POPUP,
  payload: {
    name: 'follow-up-messages-popup',
    share: {},
    selectedLead,
  },
});

export const openDownloadVideoPopup = (
  video: IVideo,
  page: string
): types.DownloadVideoPopup => ({
  type: actionTypes.OPEN_DOWNLOAD_VIDEO_POPUP,
  payload: {
    name: 'download-video-popup',
    download: { video, page },
  },
});

export const openShareBrandedNews =
  (news: INewsArticle, dontAskForProfileCompletion?: boolean): AppThunkResult =>
  (dispatch: AppThunkDispatch, getState) => {
    const state = getState();
    const user = selectUserInfo(state);
    const isPremiumUser = selectIsPremiumUser(getState());

    if (!dontAskForProfileCompletion && shouldAskForProfileCompletion(user)) {
      dispatch(openCompleteYourProfilePopup({ intent: 'see-news' }));
      return;
    }

    if (
      news.category === 'NEIGHBOURHOOD' ||
      news.category === 'MARKET_UPDATE'
    ) {
      dispatch(openVideoNewsPopup({ news }));
    } else {
      if (isPremiumUser) {
        dispatch({
          type: actionTypes.OPEN_SHARE_BRANDED_NEWS,
          payload: {
            name: 'share-branded-news',
            news: {
              newsArticle: news,
            },
          },
        });
      } else {
        dispatch(openShareBrandedNewsLegacy(news));
      }
    }
  };

export const openShareBrandedNewsLegacy = (
  news: INewsArticle
): types.OpenShareBrandedNewsLegacy => ({
  type: actionTypes.OPEN_SHARE_BRANDED_NEWS_LEGACY,
  payload: {
    name: 'share-branded-news-legacy',
    news: {
      newsArticle: news,
    },
  },
});

export const openDownloadNewsImage = (
  news: INewsArticle
): types.OpenDownloadNewsImage => ({
  type: actionTypes.OPEN_DOWNLOAD_NEWS_IMAGE,
  payload: {
    name: 'download-news-image',
    news: {
      newsArticle: news,
    },
  },
});

export const openNewsImageDownloadSuccess = (
  news?: INewsArticle
): types.OpenNewsImageDownloadSuccess => ({
  type: actionTypes.OPEN_NEWS_IMAGE_DOWNLOAD_SUCCESS,
  payload: {
    name: 'news-image-download-success-popup',
    news: {
      newsArticle: news,
    },
  },
});

export const openNewsStyleSettingsPopup = (): types.OpenNewsStyleSettings => ({
  type: actionTypes.OPEN_NEWS_STYLE_SETTINGS,
  payload: {
    name: 'news-styles-settings',
  },
});

export const openLeadGenerationPopup = (
  leads: types.ILeadsGeneration,
  type: string,
  page: Pages
): types.OpenLeadGenerationPopup => ({
  type: actionTypes.OPEN_LEAD_GENERATION_POPUP,
  payload: {
    name: 'lead-generation-popup',
    leadGeneration: { leads, page, type },
  },
});

export const openLandingSharedPopup = (
  link: string,
  page: Pages
): types.OpenLandingSharedPopup => ({
  type: actionTypes.OPEN_LANDING_SHARED_POPUP,
  payload: {
    name: 'landing-shared-popup',
    landingShared: { link, page },
  },
});

export const openEditInfoPopup = (
  info: UserEditInfoPopupObject,
  directUpdate: boolean = true,
  onUpdate: (updatedInfo) => void
): types.OpenEditInfoPopup => ({
  type: actionTypes.OPEN_EDIT_INFO_POPUP,
  payload: {
    name: 'edit-info-popup',
    editInfo: { info, directUpdate, onUpdate },
  },
});

export const openAcceptFacebookTermsPopup = (
  onClose: (accept: boolean) => void
): types.OpenAcceptFacebookTermsPopup => ({
  type: actionTypes.OPEN_ACCEPT_FACEBOOK_TERMS_POPUP,
  payload: {
    name: 'accept-facebook-terms-popup',
    acceptTOS: { onClose },
  },
});

export const openYoutubePopup = (
  src: string,
  title: string,
  parameters: string = '',
  srcFullUrl?: string
): types.OpenYoutubePopup => ({
  type: actionTypes.OPEN_YOUTUBE_POPUP,
  payload: {
    name: 'open-youtube-popup',
    youtubeVideo: { src, title, parameters, srcFullUrl },
  },
});

export const openIframePopup = (
  payload?: types.OpenIframePopup['payload']['iframePopup']
): types.OpenIframePopup => ({
  type: actionTypes.OPEN_IFRAME_POPUP,
  payload: {
    name: 'iframe-popup',
    iframePopup: payload,
  },
});

export const openAddScheduledPostBrokeragePopup = (
  fbImage: string,
  igImage?: string,
  caption?: string,
  type?: string,
  link?: Blob | string,
  agent?: Object
): types.OpenAddScheduledPostBrokeragePopup => ({
  type: actionTypes.OPEN_ADD_SCHEDULED_POST_BROKERAGE_POPUP,
  payload: {
    name: 'add-scheduled-post-brokerage-popup',
    post: { fbImage, igImage, caption, type, link, agent },
  },
});

export const openBrokerageAgentImagePostPopup = (
  agent?: any
): types.OpenBrokerageAgentImagePostPopup => ({
  type: actionTypes.OPEN_BROKERAGE_AGENT_IMAGE_POST_POPUP,
  payload: {
    name: 'brokerage-agent-image-post-popup',
    brokerageAgentImagePost: { agent },
  },
});

export const openFacebookBusinessConnectionConfirm =
  (): types.OpenFacebookBusinessConnectionConfirm => ({
    type: actionTypes.OPEN_FACEBOOK_BUSINESS_CONNECTION_CONFIRM,
    payload: {
      name: 'facebook-business-connection-confirm',
    },
  });

export const openSendSetupEmailPopup = (
  basicInfo: Object
): types.OpenSendSetupEmailPopup => ({
  type: actionTypes.OPEN_SEND_SETUP_EMAIL_POPUP,
  payload: {
    name: 'send-setup-email-popup',
    setupEmail: { basicInfo },
  },
});

export const openSchedulePostBrokerageAgentPopup = (
  agent?: Object
): types.OpenSchedulePostBrokerageAgentPopup => ({
  type: actionTypes.OPEN_SCHEDULE_POST_BROKERAGE_AGENT_POPUP,
  payload: {
    name: 'schedule-post-brokerage-agent-popup',
    info: { agent },
  },
});

export const openConfirmPopup = (
  header: string,
  body: string,
  onAccept: () => void,
  onDecline: () => void,
  acceptText?: string,
  declineText?: string
): types.OpenConfirmPopup => ({
  type: actionTypes.OPEN_CONFIRM_POPUP,
  payload: {
    name: 'confirm-popup',
    details: { header, body, onAccept, onDecline, acceptText, declineText },
  },
});

export const openSetLocationPopup = (): types.OpenSetLocationPopupAction => ({
  type: actionTypes.OPEN_SET_LOCATION_POPUP,
  payload: {
    name: 'set-location-popup',
  },
});

export const openAskVideoPopup = (): types.OpenAskVideoPopupAction => ({
  type: actionTypes.OPEN_ASK_VIDEO_POPUP,
  payload: {
    name: 'ask-video-popup',
  },
});

export const openCreateNewJourneyPopup = (
  selectedJourney: any
): types.OpenCreateNewJourneyPopupAction => ({
  type: actionTypes.OPEN_CREATE_NEW_JOURNEY_POPUP,
  payload: {
    name: 'create-new-journey-popup',
    selectedJourney: {
      selectedJourney,
    },
  },
});

export const openCalendarSettingsPopup = (
  calendarSettingsData?: types.OpenCalendarSettingsPopupAction['payload']['calendarSettingsData']
): types.OpenCalendarSettingsPopupAction => ({
  type: actionTypes.OPEN_CALENDAR_SETTINGS_POPUP,
  payload: {
    name: 'calendar-settings-popup',
    calendarSettingsData: {
      onSuccess: calendarSettingsData?.onSuccess ?? null,
      tab: calendarSettingsData?.tab ?? null,
    },
  },
});

export const openEditListingPopup = (
  editListing: types.OpenEditListingPopupAction['payload']['editListing']
): types.OpenEditListingPopupAction => ({
  type: actionTypes.OPEN_EDIT_LISTING_POPUP,
  payload: {
    name: 'edit-listing-popup',
    editListing,
  },
});

export const openAddListingPopup = (
  addListing: types.OpenAddListingPopupAction['payload']['addListing']
): types.OpenAddListingPopupAction => ({
  type: actionTypes.OPEN_ADD_LISTING_POPUP,
  payload: {
    name: 'add-listing-popup',
    addListing,
  },
});

export const openImportFromZillowConfirmPopup =
  (): types.openImportFromZillowConfirmPopupAction => ({
    type: actionTypes.OPEN_IMPORT_FROM_ZILLOW_CONFIRM_POPUP,
    payload: {
      name: 'import-from-zillow-confirm-popup',
    },
  });

export const openAddListingConfirmPopup = (
  addListingConfirm: types.OpenAddListingConfirmPopupAction['payload']['addListingConfirm']
): types.OpenAddListingConfirmPopupAction => ({
  type: actionTypes.OPEN_ADD_LISTING_CONFIRM_POPUP,
  payload: {
    name: 'add-listing-confirm-popup',
    addListingConfirm,
  },
});

export const openCompleteYourProfilePopup = (
  payload: types.PopupManagerState['completeYourProfilePopup']
): types.OpenCompleteYourProfilePopupAction => ({
  type: actionTypes.OPEN_COMPLETE_YOUR_PROFILE_POPUP,
  payload: {
    name: 'complete-your-profile-popup',
    completeYourProfilePopup: payload,
  },
});

export const openAfterPaymentPopup = (
  payload?: types.PopupManagerState['afterPaymentPopup']
): types.OpenAfterPaymentPopupAction => ({
  type: actionTypes.OPEN_AFTER_PAYMENT_POPUP,
  payload: {
    name: 'after-payment-popup',
    afterPaymentPopup: payload,
  },
});

export const openPaymentCouponPopup = (
  payload?: types.PopupManagerState['couponInfo']
): types.OpenPaymentCouponPopupAction => ({
  type: actionTypes.OPEN_PAYMENT_COUPON_POPUP,
  payload: {
    name: 'payment-coupon-popup',
    couponInfo: payload,
  },
});

export const openVideoNewsPopup = ({
  news,
  screen = 'preview',
  onClose,
}: {
  news: INewsArticle;
  screen?: 'preview' | 'customize';
  onClose?(news: INewsArticle): void;
}): types.OpenVideoNewsPopup => ({
  type: actionTypes.OPEN_VIDEO_NEWS_POPUP,
  payload: {
    name: 'video-news-popup',
    screen,
    news: {
      newsArticle: news,
      onClose: onClose ?? null,
    },
  },
});

export const openVideoTestimonialPopup = (
  testimonial: ITestimonial
): types.OpenVideoTestimonialPopup => ({
  type: actionTypes.OPEN_VIDEO_TESTIMONIAL_POPUP,
  payload: {
    name: 'video-testimonial-popup',
    testimonial: testimonial,
  },
});

export const openHyperlocalVideoPopup = (
  hyperLocal: object
): types.OpenHyperlocalVideoPopup => ({
  type: actionTypes.OPEN_HYPERLOCAL_VIDEO_POPUP,
  payload: {
    name: 'hyperlocal-video-popup',
    hyperlocalVideoInfo: hyperLocal,
  },
});

export const openContentFactoryEditTextPopup = (
  selectedContent: ISelectedContent
): types.OpenContentFactoryEditTextPopup => ({
  type: actionTypes.OPEN_CONTENT_FACTORY_EDIT_TEXT,
  payload: {
    name: 'content-factory-edit-text-popup',
    selectedContent: selectedContent,
  },
});

export const openDownloadUserVideoPopup = (
  downloadUrl: string,
  checkProgressUrl: string,
  onDownloadedVideo?: (link: string) => void
): types.OpenDownloadUserVideoPopup => ({
  type: actionTypes.OPEN_DOWNLOAD_USER_VIDEO_POPUP,
  payload: {
    name: 'download-user-video-popup',
    downloadUserVideo: {
      downloadUrl,
      checkProgressUrl,
      onDownloadedVideo,
    },
  },
});

export const openCalendarPreviewPopup = (
  data
): types.OpenCalendarPreviewPopup => {
  return {
    type: actionTypes.OPEN_CALENDAR_PREVIEW_POPUP,
    payload: {
      name: 'calendar-preview-popup',
      calendarPreview: data,
    },
  };
};

export const openShareListingPopup = (
  payload: types.OpenShareListingPopupAction['payload']['shareListing']
): types.OpenShareListingPopupAction => ({
  type: actionTypes.OPEN_SHARE_LISTING_POPUP,
  payload: {
    name: 'share-listing-popup',
    shareListing: {
      listing: payload?.listing,
      onEdit: payload?.onEdit ?? null,
      variant: payload?.variant ?? 'default',
    },
  },
});

export const updatePopupData = (
  payload: types.UpdatePopupDataAction['payload']
): types.UpdatePopupDataAction => ({
  type: actionTypes.UPDATE_POPUP_DATA,
  payload,
});

export const openSocialsSettingsPopup = (
  socialsSettings?: types.OpenSocialsSettingsPopup['payload']['socialsSettings']
): types.OpenSocialsSettingsPopup => ({
  type: actionTypes.OPEN_SOCIALS_SETTINGS_POPUP,
  payload: {
    name: 'socials-settings-popup',
    socialsSettings,
  },
});

export const openFakeEmailPopup = (): types.OpenFakeEmailPopup => ({
  type: actionTypes.OPEN_FAKE_EMAIL_POPUP,
  payload: {
    name: 'fake-email-popup',
  },
});

export const openContentFactoryGenerateVideoPopup =
  (): types.OpenContentFactoryGenerateVideoPopup => ({
    type: actionTypes.OPEN_CONTENT_FACTORY_GENERATE_VIDEO_POPUP,
    payload: {
      name: 'content-factory-generate-video-popup',
    },
  });

export const openPhoneVerificationPopup = (
  payload: types.OpenPhoneVerificationPopup['payload']['info']
): types.OpenPhoneVerificationPopup => ({
  type: actionTypes.OPEN_PHONE_VERIFICATION_POPUP,
  payload: {
    name: 'phone-verification-popup',
    info: payload,
  },
});

export const openPremiumBrandingPopup = (
  premiumBrandingPopupData?: types.OpenPremiumBrandingPopup['payload']['premiumBrandingPopupData']
): types.OpenPremiumBrandingPopup => ({
  type: actionTypes.OPEN_PREMIUM_BRANDING_POPUP,
  payload: {
    name: 'premium-branding-popup',
    premiumBrandingPopupData,
  },
});

export const openUploadHeadshotPopup = (
  headshotPopup?: types.OpenUploadHeadshotPopup['payload']['headshotPopup']
): types.OpenUploadHeadshotPopup => {
  return {
    type: actionTypes.OPEN_UPLOAD_HEADSHOT_POPUP,
    payload: {
      name: 'upload-headshot-popup',
      headshotPopup,
    },
  };
};

export const openImportNewsPopup = (
  importToBrokerage?: boolean,
  importToOrganization?: boolean
): types.OpenImportNewsPopup => ({
  type: actionTypes.OPEN_IMPORT_NEWS_POPUP,
  payload: {
    name: 'import-news-popup',
    importNews: {
      import_to_brokerage: importToBrokerage,
      import_to_organization: importToOrganization,
    },
  },
});

export const openSocialAuditPopup =
  (): AppThunkResult => async (dispatch: AppThunkDispatch, getState) => {
    const state = getState();
    const isFreeUser = selectIsFreeUser(state);

    if (isFreeUser) {
      dispatch({
        type: actionTypes.OPEN_SOCIAL_AUDIT_POPUP,
        payload: {
          name: 'social-audit-popup',
        },
      });
    }
  };

export const openSocialAuditReadyPopup =
  (): types.OpenSocialAuditReadyPopup => ({
    type: actionTypes.OPEN_SOCIAL_AUDIT_READY_POPUP,
    payload: {
      name: 'social-audit-ready-popup',
    },
  });

export const openVideoCreatorPopup = (
  payload: types.OpenVideoCreatorPopup['payload']['videoCreatorPopup']
): types.OpenVideoCreatorPopup => ({
  type: actionTypes.OPEN_VIDEO_CREATOR_POPUP,
  payload: {
    name: 'video-creator-popup',
    videoCreatorPopup: payload,
  },
});

export const openUpgradeAllPlansPopup = (): types.OpenUpgradeAllPlansPopup => ({
  type: actionTypes.OPEN_UPGRADE_ALL_PLANS_POPUP,
  payload: {
    name: 'upgrade-all-plans-popup',
  },
});

export const openInstagramReconnectPopup =
  (): types.OpenInstagramReconnectPopup => ({
    type: actionTypes.OPEN_INSTAGRAM_RECONNECT_POPUP,
    payload: {
      name: 'instagram-reconnect-popup',
    },
  });

export const openSocialConnectReminderPopup =
  (): types.OpenSocialConnectReminderPopup => ({
    type: actionTypes.OPEN_SOCIAL_CONNECT_REMINDER_POPUP,
    payload: {
      name: 'social-connect-reminder-popup',
    },
  });

export const openVideoRecorderPopup = (
  payload: types.OpenVideoRecorderPopup['payload']['videoRecorderPopup']
): types.OpenVideoRecorderPopup => ({
  type: actionTypes.OPEN_VIDEO_RECORDER_POPUP,
  payload: {
    name: 'video-recorder-popup',
    videoRecorderPopup: payload,
  },
});

export const openSixMonthsOffPopup = (): types.OpenSixMonthsOffPopup => ({
  type: actionTypes.OPEN_SIX_MONTHS_OFF_POPUP,
  payload: {
    name: 'six-months-off-popup',
  },
});

export const openSecondaryLanguageConfirmPopup =
  (): types.OpenSecondaryLanguageConfirmPopup => {
    return {
      type: actionTypes.OPEN_SECONDARY_LANGUAGE_CONFIRM_POPUP,
      payload: {
        name: 'secondary-language-confirm-popup',
      },
    };
  };
export const openPauseSubscriptionPopup =
  (): types.OpenPauseSubscriptionPopup => {
    return {
      type: actionTypes.OPEN_PAUSE_SUBSCRIPTION_POPUP,
      payload: {
        name: 'pause-subscription-popup',
      },
    };
  };

export const openConfirmDowngradeSubscriptionPopup = (
  downgradeData: any
): types.OpenConfirmDowngradeSubscriptionPopup => {
  return {
    type: actionTypes.OPEN_CONFIRM_DOWNGRADE_POPUP,
    payload: {
      name: 'confirm-downgrade-subscription-popup',
      downgradeData: downgradeData,
    },
  };
};

export const openResumeSubscriptionPopup = (
  resume_date: string
): types.OpenResumeSubscriptionPopup => {
  return {
    type: actionTypes.OPEN_RESUME_SUBSCRIPTION_POPUP,
    payload: {
      name: 'resume-subscription-popup',
      resume_date: resume_date,
    },
  };
};

export const openDowngradePlanPopup = (): types.OpenDowngradePlanPopup => {
  return {
    type: actionTypes.OPEN_DOWNGRADE_PLAN_POPUP,
    payload: {
      name: 'downgrade-plan-popup',
    },
  };
};

export const openPricingSuggestionPopup =
  (): types.OpenPricingSuggestionPopup => {
    return {
      type: actionTypes.OPEN_PRICING_SUGGESTION_POPUP,
      payload: {
        name: 'pricing-suggestion-popup',
      },
    };
  };
